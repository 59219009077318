import * as React from 'react'

function VmUserChIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 20 20',
        stroke: 'currentColor',
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      d: 'M15 16.5C15 14.6583 12.7617 13.1667 10 13.1667C7.23833 13.1667 5 14.6583 5 16.5M10 10.6667C9.11595 10.6667 8.2681 10.3155 7.64298 9.69036C7.01786 9.06523 6.66667 8.21739 6.66667 7.33333C6.66667 6.44928 7.01786 5.60143 7.64298 4.97631C8.2681 4.35119 9.11595 4 10 4C10.8841 4 11.7319 4.35119 12.357 4.97631C12.9821 5.60143 13.3333 6.44928 13.3333 7.33333C13.3333 8.21739 12.9821 9.06523 12.357 9.69036C11.7319 10.3155 10.8841 10.6667 10 10.6667Z',
    })
  )
}

const ForwardRef = React.forwardRef(VmUserChIcon)
export default ForwardRef
