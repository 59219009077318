import * as React from 'react'

function VmUserCheckIcon(
  {title, titleId, strokeArrow = null, ...props},
  svgRef
) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 20 20',
        stroke: 'currentColor',
        fill: 'none',
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props,
      {
        fill: 'none',
        stroke: 'currentColor',
      }
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      d: 'M12.5 15.8333C12.5 13.9917 10.2617 12.5 7.5 12.5C4.73833 12.5 2.5 13.9917 2.5 15.8333M17.5 8.33333L14.1667 11.6667L12.5 9.99999M7.5 9.99999C6.61595 9.99999 5.7681 9.64881 5.14298 9.02368C4.51786 8.39856 4.16667 7.55072 4.16667 6.66666C4.16667 5.78261 4.51786 4.93476 5.14298 4.30964C5.7681 3.68452 6.61595 3.33333 7.5 3.33333C8.38405 3.33333 9.2319 3.68452 9.85702 4.30964C10.4821 4.93476 10.8333 5.78261 10.8333 6.66666C10.8333 7.55072 10.4821 8.39856 9.85702 9.02368C9.2319 9.64881 8.38405 9.99999 7.5 9.99999Z',
    }),
    React.createElement('path', {
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      stroke: strokeArrow || 'currentColor',
      d: 'M17.5 8.33333L14.1667 11.6667L12.5 9.99999',
    })
  )
}

const ForwardRef = React.forwardRef(VmUserCheckIcon)
export default ForwardRef
