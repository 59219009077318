import * as React from 'react'

function VmTargetFluentIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 20 20',
        stroke: 'currentColor',
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      d: 'M9.99833 11.6667C10.4404 11.6667 10.8643 11.4911 11.1768 11.1785C11.4894 10.866 11.665 10.442 11.665 10C11.665 9.55797 11.4894 9.13405 11.1768 8.82149C10.8643 8.50893 10.4404 8.33333 9.99833 8.33333C9.5563 8.33333 9.13238 8.50893 8.81982 8.82149C8.50726 9.13405 8.33166 9.55797 8.33166 10C8.33166 10.442 8.50726 10.866 8.81982 11.1785C9.13238 11.4911 9.5563 11.6667 9.99833 11.6667ZM4.99833 10C4.99833 9.34339 5.12766 8.69321 5.37893 8.08658C5.63021 7.47996 5.9985 6.92876 6.4628 6.46447C6.92709 6.00017 7.47829 5.63188 8.08491 5.3806C8.69154 5.12933 9.34172 5 9.99833 5C10.6549 5 11.3051 5.12933 11.9117 5.3806C12.5184 5.63188 13.0696 6.00017 13.5339 6.46447C13.9982 6.92876 14.3665 7.47996 14.6177 8.08658C14.869 8.69321 14.9983 9.34339 14.9983 10C14.9983 11.3261 14.4715 12.5979 13.5339 13.5355C12.5962 14.4732 11.3244 15 9.99833 15C8.67225 15 7.40048 14.4732 6.4628 13.5355C5.52511 12.5979 4.99833 11.3261 4.99833 10ZM9.99833 6.25C9.00377 6.25 8.04994 6.64509 7.34668 7.34835C6.64342 8.05161 6.24833 9.00544 6.24833 10C6.24833 10.9946 6.64342 11.9484 7.34668 12.6517C8.04994 13.3549 9.00377 13.75 9.99833 13.75C10.9929 13.75 11.9467 13.3549 12.65 12.6517C13.3532 11.9484 13.7483 10.9946 13.7483 10C13.7483 9.00544 13.3532 8.05161 12.65 7.34835C11.9467 6.64509 10.9929 6.25 9.99833 6.25ZM1.66333 10C1.66333 5.39667 5.395 1.665 9.99833 1.665C14.6017 1.665 18.3333 5.39667 18.3333 10C18.3333 14.6033 14.6017 18.335 9.99833 18.335C5.395 18.335 1.66333 14.6033 1.66333 10ZM9.99833 2.915C8.11927 2.915 6.31717 3.66145 4.98848 4.99015C3.65978 6.31884 2.91333 8.12094 2.91333 10C2.91333 11.8791 3.65978 13.6812 4.98848 15.0099C6.31717 16.3385 8.11927 17.085 9.99833 17.085C11.8774 17.085 13.6795 16.3385 15.0082 15.0099C16.3369 13.6812 17.0833 11.8791 17.0833 10C17.0833 8.12094 16.3369 6.31884 15.0082 4.99015C13.6795 3.66145 11.8774 2.915 9.99833 2.915Z',
    })
  )
}

const ForwardRef = React.forwardRef(VmTargetFluentIcon)
export default ForwardRef
