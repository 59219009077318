import React from 'react'

export default function VmNoteTimes(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.52941 6.52941H13.5882M13.5882 10.0588H6.52941M6.52941 13.5882H10.0588M17.1176 10.0588V4.76471C17.1176 4.29668 16.9317 3.84782 16.6008 3.51687C16.2698 3.18592 15.821 3 15.3529 3H4.76471C4.29668 3 3.84782 3.18592 3.51687 3.51687C3.18592 3.84782 3 4.29668 3 4.76471V15.3529C3 15.821 3.18592 16.2698 3.51687 16.6008C3.84782 16.9317 4.29668 17.1176 4.76471 17.1176H10.0588"
        stroke="#3B4054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2218 13.4812C16.4908 14.2121 15.355 15.3479 15.355 15.3479M15.355 15.3479L13.4812 17.2218M15.355 15.3479C15.355 15.3479 16.4979 16.4908 17.2288 17.2218M15.355 15.3479C15.355 15.3479 14.2192 14.2121 13.4882 13.4812"
        stroke="#FF1212"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
