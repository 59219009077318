import * as React from 'react'

function VmWave3Icon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        fill: 'currentColor',
        viewBox: '0 0 320 44',
        strokeWidth: 0,
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M320 12.6541L311 16.9777C302 21.3013 284 29.9484 267 34.272C249 38.5955 231 38.5955 213 36.4338C196 33.7315 178 28.3271 160 22.3822C142 16.4373 124 9.41146 107 5.08789C89 0.764318 71 -0.857021 53 3.46655C36 7.79012 18 18.0586 9 23.4631L0 28.3271V44H9C18 44 36 44 53 44C71 44 89 44 107 44C124 44 142 44 160 44C178 44 196 44 213 44C231 44 249 44 267 44C284 44 302 44 311 44H320V12.6541Z',
      fillOpacity: '0.2',
    })
  )
}

const ForwardRef = React.forwardRef(VmWave3Icon)
export default ForwardRef
