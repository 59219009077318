import * as React from 'react'

function VmWave2Icon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        fill: 'currentColor',
        viewBox: '0 0 1600 198',
        strokeWidth: 0,
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z',
      fill: 'url(#VmWave2Icon)',
      fillOpacity: '0.2',
    }),
    React.createElement(
      'defs',
      {},
      React.createElement(
        'linearGradient',
        {
          gradientUnits: 'userSpaceOnUse',
          id: 'VmWave2Icon',
          x1: '50%',
          y1: '-10.959%',
          x2: '50%',
          y2: '100%',
        },
        React.createElement('stop', {
          stopColor: props?.colorstart || props?.color || 'white',
          offset: '0%',
          stopOpacity: '0.25',
        }),
        React.createElement('stop', {
          stopColor: props?.colorend || props?.color || 'white',
        })
      )
    )
  )
}

const ForwardRef = React.forwardRef(VmWave2Icon)
export default ForwardRef
