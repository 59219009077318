import React from "react"
import { VmTextAttribute } from "@/config/attributes"
import classNames from "classnames"

const defaultProps = {
  children: "VmText",
  disabled: false,
  className: "justify-center text-center",
}
// var VmText = {propTypes: VmTextAttribute, defaultProps}

interface VmTextProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  disabled?: boolean
  typeText?: string
  label?: string
}
// eslint-disable-next-line
var VmText = React.forwardRef<HTMLParagraphElement, VmTextProps>(
  (
    {
      children,
      style,
      disabled,
      className,
      onClick = () => {},
      typeText = "p",
      ...props
    },
    ref
  ) => {
    // return <p></p>
    return React.createElement(
      typeText,
      {
        "aria-label": props?.label || "",
        style: style ? style : {},
        className: classNames(disabled ? "opacity-25" : false, className),
        onClick: disabled ? () => {} : onClick,
        ref: ref,
        ...props,
      },
      children
    )
  }
)

export default VmText
