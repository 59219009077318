import React from 'react'
import classNames from 'classnames'
import rounded from '@/config/rounded'

export const hidering = '!ring-0 !ring-transparent !ring-offset-0'

const btncolors = [
  'primary',
  'secondary',
  'success',
  'danger',
  'warning',
  'info',
  'dark',
  'light',
] as const

type ButtonColor = (typeof btncolors)[number]
type ButtonVariant = '' | 'ghost' | 'outline'
type ButtonSize = 'sm' | 'md' | 'lg' | string
type RoundedType = keyof typeof rounded

interface VmButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  rounded?: RoundedType
  active?: boolean
  full?: boolean
  size?: ButtonSize
  hidering?: boolean
  href?: string
  to?: string
  variant?: ButtonVariant
  color?: ButtonColor | string
  label?: string
}

const VmButton = React.forwardRef<HTMLButtonElement, VmButtonProps>(
  (
    {
      className,
      onClick,
      disabled,
      active,
      full,
      color = 'primary',
      size,
      rounded: propsRounded = 'xl',
      variant,
      children = 'Button',
      hidering: hideRing,
      label,
      ...attributes
    },
    innerRef
  ) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!disabled && onClick) {
        onClick(e)
      }
    }

    const isLink = attributes.to || attributes.href
    const classes = classNames(
      'btn',
      hideRing && hidering,
      className,
      active && 'active',
      btncolors.includes(color as ButtonColor)
        ? `${color}${variant ? `-${variant}` : ''}`
        : `bg-[${color}]`,
      size && `${size}`,
      full && 'w-full',
      rounded[propsRounded],
      disabled && !isLink && 'opacity-25',
      'PoppinsRegular'
    )

    if (isLink) {
      // Placeholder for VmLink component
      return null
    }

    return (
      <button
        {...attributes}
        aria-label={label || ''}
        className={classes}
        type="button"
        disabled={disabled}
        onClick={handleClick}
        ref={innerRef}
      >
        {children}
      </button>
    )
  }
)

VmButton.displayName = 'VmButton'

export default VmButton
