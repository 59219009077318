import SimpleToast, {SimpleToastProps} from '@/components/SimpleToast'
import VmMobileWrapper from '@/components/VmMobileWrapper'
import VmSpinnerFullScreen from '@/components/vmspinner/vmspinnerfullscreen'
import {defaultpropssimpletoast} from '@/entries/components/simpletoast'
import '@/styles/globals.scss'
import {
  APP_VARIABLES,
  MAPS_API_KEY,
  STRG_OPT,
  USER_VARIABLES,
} from '@/utils/core'
import {getValue} from '@/utils/datausr'
import {getLclStorage, setLclStorage} from '@/utils/localstorage'
import {NextUIProvider} from '@nextui-org/react'
import {useLoadScript} from '@react-google-maps/api'
import type {AppProps} from 'next/app'
import {Poppins} from 'next/font/google'
import Head from 'next/head'
import {useEffect, useMemo, useState} from 'react'

const poppins = Poppins({
  weight: ['400', '700', '300'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
})

type ComponentProps = {
  Component: {
    title: string | undefined
    layout: Node | any
  }
}
type VmAppProps = AppProps & ComponentProps
export default function App({Component, pageProps}: VmAppProps) {
  const getLoading = () => {
    const strgloading =
      getLclStorage(APP_VARIABLES.AppLoading, STRG_OPT) === 'true'
    return strgloading
  }
  const getReloadApp = () => {
    const strgloading = getLclStorage(APP_VARIABLES.ReloadApp, STRG_OPT)
    return strgloading
  }
  const [issafe, setissafe] = useState(false)
  const [loading, setloading] = useState(false)
  const [reloadApp, setreloadApp] = useState<any>(getReloadApp())
  const [propssimpletoast, setpropssimpletoast] = useState<SimpleToastProps>(
    defaultpropssimpletoast
  )

  const propsGMaps: any = useMemo(() => {
    return {
      googleMapsApiKey: MAPS_API_KEY as string,
      libraries: ['places'],
      language: 'id',
      region: 'ID',
    }
  }, [MAPS_API_KEY]) /* eslint-disable-line */
  const {isLoaded} = useLoadScript(propsGMaps)
  const onShowToast = ({
    message = '-',
    type = 'Success',
    descriptions = null,
  }: {
    message?: SimpleToastProps['toastertitle']
    type?: SimpleToastProps['toastertype']
    descriptions?: SimpleToastProps['toasterdesc']
  }) => {
    var newtimeout: any = null
    newtimeout = setTimeout(() => {
      setpropssimpletoast(defaultpropssimpletoast)
      clearTimeout(newtimeout)
    }, 3000)
    var toastericon = 'VmCheckCircleIcon'
    if (type === 'Fail') {
      toastericon = 'VmXCircleIcon'
    }
    if (type === 'Warning') {
      toastericon = 'VmExclamationCircleIcon'
    }
    if (type === 'Info') {
      toastericon = 'VmInformationCircleIcon'
    }
    return setpropssimpletoast({
      toastericon,
      toastertitle: message,
      toastervisible: true,
      toastertype: type,
      toasterdesc: descriptions,
    })
  }
  const listener = () => {
    var toastData: any = getLclStorage(APP_VARIABLES.SimpleToast, STRG_OPT)
    if (toastData === null) {
      toastData = {length: false}
    }
    var newtimeout: any = null
    newtimeout = setTimeout(() => {
      if (toastData?.length) {
        toastData = JSON.parse(toastData)
        if (typeof toastData?.message === 'string') {
          onShowToast(toastData)
          setLclStorage(APP_VARIABLES.SimpleToast, '', STRG_OPT)
        }
      }
      if (!(reloadApp === getReloadApp())) {
        setreloadApp(getReloadApp())
      }
      setloading(getLoading())
      clearTimeout(newtimeout)
      listener()
    }, 500)
  }
  useMemo(() => {
    listener()
  }, [reloadApp]) /* eslint-disable-line */

  function detectDevice(): 'mobile' | 'desktop' {
    if (typeof window === 'undefined') {
      return 'desktop'
    }

    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera

    // Deteksi Android (termasuk webview)
    if (/android/i.test(userAgent.toLowerCase()) || 'Android' in window) {
      return 'mobile'
    }

    // Deteksi iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return 'mobile'
    }

    // Deteksi webview umum
    if (/wv/i.test(userAgent)) {
      return 'mobile'
    }

    // Deteksi mobile lainnya
    if (
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent.toLowerCase()
      )
    ) {
      return 'mobile'
    }

    // Deteksi berdasarkan lebar layar
    if (window.innerWidth <= 768) {
      return 'mobile'
    }

    // Deteksi berdasarkan touch support
    if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
      return 'mobile'
    }

    // Deteksi menggunakan media query
    if (
      window.matchMedia('(max-width: 768px)').matches ||
      window.matchMedia('(pointer: coarse)').matches
    ) {
      return 'mobile'
    }

    return 'desktop'
  }

  useEffect(() => {
    const device = detectDevice()
    setLclStorage('_syspf', device, {type: 2})
    if (typeof process !== 'undefined' && typeof window !== 'undefined') {
      const pathname = window.location?.pathname
      const whitelistpathname = process.env.NEXT_PUBLIC_WHITE_LIST_PATHNAME
      const splitwhitelistpathname = `${whitelistpathname}`.split('|')
      let w: any = window
      if (
        splitwhitelistpathname?.length > 0
          ? splitwhitelistpathname.findIndex(fn => pathname.includes(fn)) < 0
          : true
      ) {
        // w.location = 'https://vmedis.com'
        setissafe(true)
      } else {
        if (whitelistpathname?.length === 0) {
          // w.location = 'https://vmedis.com'
          setissafe(true)
        } else {
          setissafe(true)
        }
      }
    } else {
      setissafe(true)
    }

    return () => {
      setLclStorage(APP_VARIABLES.AppLoading, '', STRG_OPT)
    }
  }, []) /* eslint-disable-line */
  const Layout =
    Component?.layout || (({children = ''}): any => <>{children}</>)
  const Title = Component?.title || (() => <title>Vmedis</title>)
  const appscomponent = useMemo(() => {
    return (
      <>
        <SimpleToast {...propssimpletoast} />
        <VmSpinnerFullScreen visible={loading || !isLoaded} />
      </>
    )
  }, [propssimpletoast, loading, isLoaded]) /* eslint-disable-line */
  const newPageProps = pageProps
  const appContent = useMemo(() => {
    if (!isLoaded || !issafe) {
      return <></>
    }
    return (
      <VmMobileWrapper>
        <Layout>
          <Component {...newPageProps} />
        </Layout>
      </VmMobileWrapper>
    )
  }, [newPageProps, reloadApp, isLoaded, issafe]) /* eslint-disable-line */
  return (
    <main className={`${poppins.className}`}>
      <Head>
        <Title />
      </Head>
      <NextUIProvider>
        {appContent}
        {appscomponent}
      </NextUIProvider>
    </main>
  )
}
