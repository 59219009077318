import * as Icons from '@/components/icons/solid'
import * as OutlineIcons from '@/components/icons/outline'
import * as AvatarIcons from '@/components/icons/avatar'
import * as AbstractIcons from '@/components/icons/abstract'
import {string, number, shape, oneOf, oneOfType} from 'prop-types'
import {CSSProperties} from '@/config/attributes'

const iconNames = Object.keys(Icons)
const iconOutlineNames = Object.keys(OutlineIcons)
const iconAvatarNames = Object.keys(AvatarIcons)
const iconAbstractNames = Object.keys(AbstractIcons)
const VmIcons = ({...attributes}) => {
  const {
    title = null,
    titleId = null,
    label = null,
    usesizepx = true,
    name = '',
    size = 12,
    color = 'currentColor',
    variant = 'solid',
    style,
  } = attributes
  const newattributes = attributes
  delete attributes.size
  if (variant === 'avatar') {
    const VmIcon = AvatarIcons[name]
    if (!VmIcon) {
      throw new TypeError(`${name} is not valid icon name or type`)
    }
    return (
      <VmIcon
        {...Object.assign(newattributes, label ? {'aria-label': label} : {})}
        height={usesizepx ? `${size}px` : size}
        width={usesizepx ? `${size}px` : size}
        title={title}
        titleId={titleId}
        style={style}
      />
    )
  }
  if (variant === 'abstract') {
    const VmIcon = AbstractIcons[name]
    if (!VmIcon) {
      throw new TypeError(`${name} is not valid icon name or type`)
    }
    return (
      <VmIcon
        {...Object.assign(newattributes, label ? {'aria-label': label} : {})}
        height={usesizepx ? `${size}px` : size}
        width={usesizepx ? `${size}px` : size}
        title={title}
        titleId={titleId}
        style={style}
        color={color}
      />
    )
  }
  const VmIcon = variant === 'outline' ? OutlineIcons[name] : Icons[name]
  if (!VmIcon) {
    throw new TypeError(`${name} is not valid icon name`)
  }
  return (
    <VmIcon
      stroke={variant === 'outline' ? color : '#00000000'}
      fill={variant === 'outline' ? '#00000000' : color}
      {...Object.assign(newattributes, label ? {'aria-label': label} : {})}
      height={usesizepx ? `${size}px` : size}
      width={usesizepx ? `${size}px` : size}
      title={title}
      titleId={titleId}
      style={style}
    />
  )
}
// VmIcons.defaultProps = {
//   title: null,
//   titleId: null,
//   size: 12,
//   color: 'currentColor',
// }
VmIcons.propTypes = {
  title: string,
  titleId: string,
  name: string.isRequired,
  size: oneOfType([number, string]),
  color: string,
  label: string,
  style: shape(CSSProperties),
  variant: oneOf(['solid', 'outline', 'avatar', 'abstract']),
  className: string,
  strokeArrow: string,
}

export {iconNames, iconOutlineNames, iconAvatarNames, iconAbstractNames}
export default VmIcons
