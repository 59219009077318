import React from "react"

const defaultStyleView = {}

interface VmViewProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  label?: string
}
// var VmView = {propTypes: VmViewAttribute, defaultProps}
var VmView = React.forwardRef<HTMLDivElement, VmViewProps>(
  ({ children, style, ...props }, ref) => {
    // return <div></div>
    return React.createElement(
      "div",
      Object.assign(
        {
          style: { ...defaultStyleView, ...style },
          ref: ref,
          ...props,
        },
        props?.label
          ? {
              "aria-label": props.label,
            }
          : { "aria-label": "" }
      ),
      children
    )
  }
)

VmView.displayName = 'VmView'
export default VmView
