const rounded: any = {
  /**
   * border-radius: 0px
   */
  none: "rounded-none",
  /**
   * border-radius: 0.125rem; 2px
   */
  sm: "rounded-sm",
  /**
   * border-radius: 0.25rem; 4px
   */
  rounded: "rounded",
  /**
   * border-radius: 0.375rem; 6px
   */
  md: "rounded-md",
  /**
   * border-radius: 0.5rem; 8px
   */
  lg: "rounded-lg",
  /**
   * border-radius: 0.75rem; 12px
   */
  xl: "rounded-xl",
  /**
   * border-radius: 1rem; 16px
   */
  "2xl": "rounded-2xl",
  /**
   * border-radius: 1.5rem; 24px
   */
  "3xl": "rounded-3xl",
  /**
   * border-radius: 9999px;
   */
  full: "rounded-full",
  /**
   * border-top-left-radius: 0px;
   * border-top-right-radius: 0px;
   */
  "t-none": "rounded-t-none",
  /**
   * border-top-left-radius: 0.125rem; 2px
   * border-top-right-radius: 0.125rem; 2px
   */
  "t-sm": "rounded-t-sm",
  /**
   * border-top-left-radius: 0.25rem; 4px
   * border-top-right-radius: 0.25rem; 4px
   */
  t: "rounded-t",
  /**
   * border-top-left-radius: 0.375rem; 6px
   * border-top-right-radius: 0.375rem; 6px
   */
  "t-md": "rounded-t-md",
  /**
   * border-top-left-radius: 0.5rem; 8px
   * border-top-right-radius: 0.5rem; 8px
   */
  "t-lg": "rounded-t-lg",
  /**
   * border-top-left-radius: 0.75rem; 12px
   * border-top-right-radius: 0.75rem; 12px
   */
  "t-xl": "rounded-t-xl",
  /**
   * border-top-left-radius: 1rem; 16px
   * border-top-right-radius: 1rem; 16px
   */
  "t-2xl": "rounded-t-2xl",
  /**
   * border-top-left-radius: 1.5rem; 24px
   * border-top-right-radius: 1.5rem; 24px
   */
  "t-3xl": "rounded-t-3xl",
  /**
   * border-top-left-radius: 9999px;
   * border-top-right-radius: 9999px;
   */
  "t-full": "rounded-t-full",
  /**
   * border-top-right-radius: 0px;
   * border-bottom-right-radius: 0px;
   */
  "r-none": "rounded-r-none",
  /**
   * border-top-right-radius: 0.125rem; 2px
   * border-bottom-right-radius: 0.125rem; 2px
   */
  "r-sm": "rounded-r-sm",
  /**
   * border-top-right-radius: 0.25rem; 4px
   * border-bottom-right-radius: 0.25rem; 4px
   */
  r: "rounded-r",
  /**
   * border-top-right-radius: 0.375rem; 6px
   * border-bottom-right-radius: 0.375rem; 6px
   */
  "r-md": "rounded-r-md",
  /**
   * border-top-right-radius: 0.5rem; 8px
   * border-bottom-right-radius: 0.5rem; 8px
   */
  "r-lg": "rounded-r-lg",
  /**
   * border-top-right-radius: 0.75rem; 12px
   * border-bottom-right-radius: 0.75rem; 12px
   */
  "r-xl": "rounded-r-xl",
  /**
   * border-top-right-radius: 1rem; 16px
   * border-bottom-right-radius: 1rem; 16px
   */
  "r-2xl": "rounded-r-2xl",
  /**
   * border-top-right-radius: 1.5rem; 24px
   * border-bottom-right-radius: 1.5rem; 24px
   */
  "r-3xl": "rounded-r-3xl",
  /**
   * border-top-right-radius: 9999px;
   * border-bottom-right-radius: 9999px;
   */
  "r-full": "rounded-r-full",
  /**
   * border-bottom-right-radius: 0px;
   * border-bottom-left-radius: 0px;
   */
  "b-none": "rounded-b-none",
  /**
   * border-bottom-right-radius: 0.125rem; 2px
   * border-bottom-left-radius: 0.125rem; 2px
   */
  "b-sm": "rounded-b-sm",
  /**
   * border-bottom-right-radius: 0.25rem; 4px
   * border-bottom-left-radius: 0.25rem; 4px
   */
  b: "rounded-b",
  /**
   * border-bottom-right-radius: 0.375rem; 6px
   * border-bottom-left-radius: 0.375rem; 6px
   */
  "b-md": "rounded-b-md",
  /**
   * border-bottom-right-radius: 0.5rem; 8px
   * border-bottom-left-radius: 0.5rem; 8px
   */
  "b-lg": "rounded-b-lg",
  /**
   * border-bottom-right-radius: 0.75rem; 12px
   * border-bottom-left-radius: 0.75rem; 12px
   */
  "b-xl": "rounded-b-xl",
  /**
   * border-bottom-right-radius: 1rem; 16px
   * border-bottom-left-radius: 1rem; 16px
   */
  "b-2xl": "rounded-b-2xl",
  /**
   * border-bottom-right-radius: 1.5rem; 24px
   * border-bottom-left-radius: 1.5rem; 24px
   */
  "b-3xl": "rounded-b-3xl",
  /**
   * border-bottom-right-radius: 9999px;
   * border-bottom-left-radius: 9999px;
   */
  "b-full": "rounded-b-full",
  /**
   * border-top-left-radius: 0px;
   * border-bottom-left-radius: 0px;
   */
  "l-none": "rounded-l-none",
  /**
   * border-top-left-radius: 0.125rem; 2px
   * border-bottom-left-radius: 0.125rem; 2px
   */
  "l-sm": "rounded-l-sm",
  /**
   * border-top-left-radius: 0.25rem; 4px
   * border-bottom-left-radius: 0.25rem; 4px
   */
  l: "rounded-l",
  /**
   * border-top-left-radius: 0.375rem; 6px
   * border-bottom-left-radius: 0.375rem; 6px
   */
  "l-md": "rounded-l-md",
  /**
   * border-top-left-radius: 0.5rem; 8px
   * border-bottom-left-radius: 0.5rem; 8px
   */
  "l-lg": "rounded-l-lg",
  /**
   * border-top-left-radius: 0.75rem; 12px
   * border-bottom-left-radius: 0.75rem; 12px
   */
  "l-xl": "rounded-l-xl",
  /**
   * border-top-left-radius: 1rem; 16px
   * border-bottom-left-radius: 1rem; 16px
   */
  "l-2xl": "rounded-l-2xl",
  /**
   * border-top-left-radius: 1.5rem; 24px
   * border-bottom-left-radius: 1.5rem; 24px
   */
  "l-3xl": "rounded-l-3xl",
  /**
   * border-top-left-radius: 9999px;
   * border-bottom-left-radius: 9999px;
   */
  "l-full": "rounded-l-full",
  /**
   * border-top-left-radius: 0px;
   */
  "tl-none": "rounded-tl-none",
  /**
   * border-top-left-radius: 0.125rem; 2px
   */
  "tl-sm": "rounded-tl-sm",
  /**
   * border-top-left-radius: 0.25rem; 4px
   */
  tl: "rounded-tl",
  /**
   * border-top-left-radius: 0.375rem; 6px
   */
  "tl-md": "rounded-tl-md",
  /**
   * border-top-left-radius: 0.5rem; 8px
   */
  "tl-lg": "rounded-tl-lg",
  /**
   * border-top-left-radius: 0.75rem; 12px
   */
  "tl-xl": "rounded-tl-xl",
  /**
   * border-top-left-radius: 1rem; 16px
   */
  "tl-2xl": "rounded-tl-2xl",
  /**
   * border-top-left-radius: 1.5rem; 24px
   */
  "tl-3xl": "rounded-tl-3xl",
  /**
   * border-top-left-radius: 9999px;
   */
  "tl-full": "rounded-tl-full",
  /**
   * border-top-right-radius: 0px;
   */
  "tr-none": "rounded-tr-none",
  /**
   * border-top-right-radius: 0.125rem; 2px
   */
  "tr-sm": "rounded-tr-sm",
  /**
   * border-top-right-radius: 0.25rem; 4px
   */
  tr: "rounded-tr",
  /**
   * border-top-right-radius: 0.375rem; 6px
   */
  "tr-md": "rounded-tr-md",
  /**
   * border-top-right-radius: 0.5rem; 8px
   */
  "tr-lg": "rounded-tr-lg",
  /**
   * border-top-right-radius: 0.75rem; 12px
   */
  "tr-xl": "rounded-tr-xl",
  /**
   * border-top-right-radius: 1rem; 16px
   */
  "tr-2xl": "rounded-tr-2xl",
  /**
   * border-top-right-radius: 1.5rem; 24px
   */
  "tr-3xl": "rounded-tr-3xl",
  /**
   * border-top-right-radius: 9999px;
   */
  "tr-full": "rounded-tr-full",
  /**
   * border-bottom-right-radius: 0px;
   */
  "br-none": "rounded-br-none",
  /**
   * border-bottom-right-radius: 0.125rem; 2px
   */
  "br-sm": "rounded-br-sm",
  /**
   * border-bottom-right-radius: 0.25rem; 4px
   */
  br: "rounded-br",
  /**
   * border-bottom-right-radius: 0.375rem; 6px
   */
  "br-md": "rounded-br-md",
  /**
   * border-bottom-right-radius: 0.5rem; 8px
   */
  "br-lg": "rounded-br-lg",
  /**
   * border-bottom-right-radius: 0.75rem; 12px
   */
  "br-xl": "rounded-br-xl",
  /**
   * border-bottom-right-radius: 1rem; 16px
   */
  "br-2xl": "rounded-br-2xl",
  /**
   * border-bottom-right-radius: 1.5rem; 24px
   */
  "br-3xl": "rounded-br-3xl",
  /**
   * border-bottom-right-radius: 9999px;
   */
  "br-full": "rounded-br-full",
  /**
   * border-bottom-left-radius: 0px;
   */
  "bl-none": "rounded-bl-none",
  /**
   * border-bottom-left-radius: 0.125rem; 2px
   */
  "bl-sm": "rounded-bl-sm",
  /**
   * border-bottom-left-radius: 0.25rem; 4px
   */
  bl: "rounded-bl",
  /**
   * border-bottom-left-radius: 0.375rem; 6px
   */
  "bl-md": "rounded-bl-md",
  /**
   * border-bottom-left-radius: 0.5rem; 8px
   */
  "bl-lg": "rounded-bl-lg",
  /**
   * border-bottom-left-radius: 0.75rem; 12px
   */
  "bl-xl": "rounded-bl-xl",
  /**
   * border-bottom-left-radius: 1rem; 16px
   */
  "bl-2xl": "rounded-bl-2xl",
  /**
   * border-bottom-left-radius: 1.5rem; 24px
   */
  "bl-3xl": "rounded-bl-3xl",
  /**
   * border-bottom-left-radius: 9999px;
   */
  "bl-full": "rounded-bl-full",
}

export default rounded
