import React from 'react'

export default function VmLocationUp() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      stroke="currentColor"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1667 9.16667C15.0746 9.16667 15 9.24129 15 9.33333C15 11.2833 13.375 13.8667 10 16.95C6.62501 13.8667 5.00001 11.2917 5.00001 9.33333C5.00001 6.30833 7.20834 4.16667 10 4.16667C10.4397 4.16667 10.8333 3.83615 10.8333 3.39651V3.27603C10.8333 2.86165 10.5237 2.50717 10.1093 2.50083C10.073 2.50028 10.0366 2.5 10 2.5C6.50001 2.5 3.33334 5.18333 3.33334 9.33333C3.33334 12.1 5.55834 15.375 10 19.1667C14.4417 15.375 16.6667 12.1 16.6667 9.33333C16.6667 9.24129 16.5921 9.16667 16.5 9.16667H15.1667Z"
        fill="#3B4054"
      />
      <path
        d="M10 10.8333C10.9205 10.8333 11.6667 10.0871 11.6667 9.16667C11.6667 8.24619 10.9205 7.5 10 7.5C9.07954 7.5 8.33334 8.24619 8.33334 9.16667C8.33334 10.0871 9.07954 10.8333 10 10.8333Z"
        fill="#3B4054"
      />
      <path
        d="M15.5 6L15.5 1M15.5 1L13 3.5M15.5 1L18 3.5"
        stroke="#FF1212"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
