import React from 'react'

export default function VmClockDown(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.52941 6.52941H13.5882M13.5882 10.0588H6.52941M6.52941 13.5882H10.0588M17.1176 10.0588V4.76471C17.1176 4.29668 16.9317 3.84782 16.6008 3.51687C16.2698 3.18592 15.821 3 15.3529 3H4.76471C4.29668 3 3.84782 3.18592 3.51687 3.51687C3.18592 3.84782 3 4.29668 3 4.76471V15.3529C3 15.821 3.18592 16.2698 3.51687 16.6008C3.84782 16.9317 4.29668 17.1176 4.76471 17.1176H10.0588"
        stroke="#3B4054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.35 12.71C15.35 13.7437 15.35 15.35 15.35 15.35M15.35 15.35L15.35 18M15.35 15.35C15.35 15.35 16.9663 15.35 18 15.35M15.35 15.35C15.35 15.35 13.7438 15.35 12.71 15.35"
        stroke="#49C834"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
