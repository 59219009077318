import React, {Fragment, ReactElement, ReactNode, useState} from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalProps,
  Button,
  useDisclosure,
  RadioGroup,
  Radio,
  Input,
} from '@nextui-org/react'
import {VmView} from '..'

interface VmModalItem {
  key: string
  label: string
}
interface VmModalListProps {
  title: string
  data: VmModalItem[]
  onSelectionChange: (item: VmModalItem) => any
  placeholderInput?: string
  customInput: ReactElement
}
export default function VmModalList(props: VmModalListProps) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure()
  const [scrollBehavior, setScrollBehavior] =
    useState<ModalProps['scrollBehavior']>('inside')
  const [cari, setCari] = useState('')

  let filteredData = props.data.filter(item => {
    let regex = new RegExp(cari, 'i')
    let pos = item.label.search(regex)
    return pos != -1
  })

  return (
    <div className="flex flex-col gap-2">
      <VmView
        onClick={() => {
          onOpen()
          setCari('')
        }}
      >
        {props.customInput}
      </VmView>
      {/* <Button onPress={onOpen}>Open Modal</Button> */}
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        scrollBehavior={scrollBehavior}
        placement="center"
        className="flex w-full max-w-sm outline-none shadow-none"
      >
        <ModalContent className="px-4">
          {onClose => (
            <VmView className="bg-white rounded-md">
              <ModalHeader className="flex flex-col gap-1 ">
                {props.title}
              </ModalHeader>
              <ModalBody>
                <Input
                  type="text"
                  variant="faded"
                  placeholder={props.placeholderInput}
                  onChange={e => setCari(e.target.value)}
                  value={cari}
                />
                <VmView className="h-full max-h-48 overflow-y-scroll">
                  {filteredData.map((item, i) => {
                    return (
                      <Fragment key={i}>
                        <p
                          onClick={e => {
                            props.onSelectionChange(item)
                            onClose()
                          }}
                          className="py-3 px-2"
                        >
                          {item.label}
                        </p>
                      </Fragment>
                    )
                  })}
                </VmView>
              </ModalBody>
            </VmView>
          )}
        </ModalContent>
      </Modal>
    </div>
  )
}
