const APP_VARIABLES = {
    SimpleToast: 'ts',
    AppLoading: 'al',
    ReloadApp: 'rac',
  },
  STRG_OPT = {type: 2}
const showlog = false
const USER_VARIABLES = {
  GeoPosition: 'up',
}
const MAPS_API_KEY = process.env.NEXT_PUBLIC_G_MAPS_API_KEY

export {APP_VARIABLES, USER_VARIABLES, STRG_OPT, MAPS_API_KEY, showlog}
