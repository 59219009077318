import React from 'react'
import VmView from '../VmView'
import {Spinner} from '@nextui-org/react'
import classNames from 'classnames'

type Props = {
  visible?: boolean | undefined
}

export default function VmSpinnerFullScreen({visible = false}: Props) {
  return visible ? (
    <VmView
      className={classNames(
        'fixed inset-0 flex',
        'justify-center items-center',
        'bg-slate-400/50',
        visible ? 'z-50' : '-z-50'
      )}
    >
      <VmView className="flex p-5 rounded-xl justify-center items-center bg-white">
        <Spinner />
      </VmView>
    </VmView>
  ) : (
    <></>
  )
}
