import React from 'react'

export default function VmClockDown(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 15.8333C12.5 13.9917 10.2617 12.5 7.5 12.5C4.73833 12.5 2.5 13.9917 2.5 15.8333M17.5 8.33333L14.1667 11.6667L12.5 10M7.5 10C6.61595 10 5.7681 9.64881 5.14298 9.02369C4.51786 8.39857 4.16667 7.55072 4.16667 6.66667C4.16667 5.78261 4.51786 4.93476 5.14298 4.30964C5.7681 3.68452 6.61595 3.33333 7.5 3.33333C8.38405 3.33333 9.2319 3.68452 9.85702 4.30964C10.4821 4.93476 10.8333 5.78261 10.8333 6.66667C10.8333 7.55072 10.4821 8.39857 9.85702 9.02369C9.2319 9.64881 8.38405 10 7.5 10Z"
        stroke="#3B4054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 8.33333L14.1667 11.6667L12.5 9.99999"
        stroke="#49C834"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
