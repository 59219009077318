'use client'
import React from 'react'
import VmView from './VmView'

const VmMobileWrapper: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
  return (
    <div className="flex justify-center min-h-screen bg-gray-100">
      <div className={`w-full sm:max-w-sm bg-white shadow-md overflow-hidden`}>
        {children}
      </div>
    </div>
  )
}

export default VmMobileWrapper
