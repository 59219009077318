import React from 'react'
import {
  oneOfType,
  oneOf,
  array,
  object,
  string,
  func,
  node,
  bool,
} from 'prop-types'
import classNames from 'classnames'
import rounded from '@/config/rounded'

const VmButtonGroup = props => {
  const {
    className,
    innerRef,
    size,
    vertical,
    children,
    rounded: propsrounded,
    color,
    ...attributes
  } = props
  const classes = classNames(
    className,
    size ? 'text-' + size : false,
    vertical ? 'flex flex-col' : false,
    propsrounded ? rounded[propsrounded] : false,
    color ? color : 'bg-blue-500',
    'w-fit'
  )

  return (
    <div className={classes} role="group" {...attributes} ref={innerRef}>
      {children}
    </div>
  )
}

VmButtonGroup.propTypes = {
  className: oneOfType([string, array, object]),
  innerRef: oneOfType([object, func]),
  size: string,
  children: node,
  rounded: oneOf(Object.keys(rounded)),
  vertical: bool,
}

export default VmButtonGroup
