import React from 'react'

export default function VmClockUp(props) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="currentColor"
      // stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4867 9.44584C16.5771 7.92651 16.2032 6.41562 15.4146 5.11385C14.6259 3.81209 13.4599 2.78108 12.0714 2.15777C10.6828 1.53447 9.13753 1.34837 7.64072 1.6242C6.1439 1.90004 4.76643 2.62474 3.69129 3.70206C2.61615 4.77937 1.89421 6.15829 1.6214 7.65566C1.34858 9.15303 1.53779 10.698 2.16389 12.0852C2.78999 13.4725 3.82335 14.6365 5.1267 15.4225C6.43005 16.2085 7.94169 16.5794 9.46083 16.4858"
        stroke="#3B4054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99997 4.83334V9.00001L11.5 11.5"
        stroke="#3B4054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8333 17.3333L14.8333 12.3333M14.8333 12.3333L12.3333 14.8333M14.8333 12.3333L17.3333 14.8333"
        stroke="#FF1212"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
