import * as React from 'react'

function VmTargetStreamLineIcon(
  {title, titleId, strokeArrow = null, ...props},
  svgRef
) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 20 20',
        stroke: 'currentColor',
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      d: 'M17.9759 11.0952C17.862 12.5265 17.3397 13.895 16.4709 15.0382C15.6021 16.1813 14.4234 17.0511 13.0748 17.5441C11.7263 18.0371 10.2646 18.1326 8.86332 17.8193C7.46208 17.506 6.18023 16.797 5.17006 15.7766C4.15988 14.7563 3.46385 13.4674 3.16464 12.063C2.86544 10.6587 2.97564 9.19803 3.48217 7.8545C3.98869 6.51098 4.87024 5.34109 6.02207 4.48383C7.17391 3.62657 8.54763 3.11797 9.98001 3.01846',
    }),
    React.createElement('path', {
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      d: 'M13.7184 11.7574C13.51 12.2937 13.1713 12.7696 12.7328 13.1421C12.2943 13.5146 11.7698 13.7718 11.2069 13.8907C10.644 14.0095 10.0603 13.9862 9.50864 13.8227C8.957 13.6593 8.4548 13.3609 8.04745 12.9546C7.6401 12.5483 7.34045 12.0469 7.1756 11.4957C7.01075 10.9445 6.98589 10.3609 7.10328 9.79762C7.22068 9.23438 7.47661 8.70929 7.84795 8.26982C8.21928 7.83036 8.6943 7.49038 9.23005 7.28065M10.4992 10.4998L13.3838 7.61525M13.3838 7.61525L15.6914 8.19216L17.999 5.88453L15.6914 5.30763L15.1145 3L12.8069 5.30763L13.3838 7.61525Z',
    }),
    React.createElement('path', {
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      stroke: strokeArrow || 'currentColor',
      d: 'M10.4992 10.4998L13.3837 7.61525M13.3837 7.61525L15.6914 8.19216L17.999 5.88453L15.6914 5.30763L15.1145 3L12.8068 5.30763L13.3837 7.61525Z',
    })
  )
}

const ForwardRef = React.forwardRef(VmTargetStreamLineIcon)
export default ForwardRef
