import React from 'react'

export default function VmClockDown() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="currentColor"
      // stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4867 9.44584C16.5772 7.92651 16.2032 6.41562 15.4146 5.11385C14.626 3.81209 13.4599 2.78108 12.0714 2.15777C10.6829 1.53447 9.13755 1.34837 7.64073 1.6242C6.14392 1.90004 4.76645 2.62474 3.6913 3.70206C2.61616 4.77937 1.89423 6.15829 1.62141 7.65566C1.3486 9.15303 1.53781 10.698 2.16391 12.0852C2.79001 13.4725 3.82337 14.6365 5.12672 15.4225C6.43006 16.2085 7.94171 16.5794 9.46085 16.4858"
        stroke="#3B4054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 4.83334V9.00001L11.5 11.5"
        stroke="#3B4054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8333 12.3333L14.8333 17.3333M14.8333 17.3333L12.3333 14.8333M14.8333 17.3333L17.3333 14.8333"
        stroke="#49C834"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
