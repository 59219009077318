import classNames from 'classnames'
import {VmPlusIcon} from '@/components/icons/solid'
import React from 'react'

export default function VmFab({onPress, className, iconClassName, hidden}) {
  return hidden ? null : (
    <div className="fixed bottom-16 right-4 ">
      <button
        onClick={onPress}
        className={classNames(
          'shadow-xl rounded-full p-2 border border-blue-500',
          className
        )}
        style={
          {
            // boxShadow: '3px 3px 11px 1px #747474',
            // WebkitBoxShadow: '3px 3px 11px 1px #747474',
          }
        }
      >
        <VmPlusIcon
          height={`${32}px`}
          width={`${32}px`}
          className={classNames('', iconClassName)}
        />
      </button>
    </div>
  )
}
